<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>

    <div class="task_box">
      <!-- 日期筛选 -->
      <div class="date_search">
        <el-date-picker
          @change="datePickerChange"
          v-model="dateVal"
          type="daterange"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <div class="btns" @click="searchTime">查询</div>
      </div>
      <!-- 树状图 图表 -->
      <div class="chart_box">
        <div
          id="chart"
          style="
            height: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          "
        ></div>
      </div>
      <!-- 数据表格 -->
      <div class="table_boxs">
        <el-table
          ref="multipleTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="id"
            width="80"
            label="序号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="设备名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="type"
            label="设备类型"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="num"
            label="累计次数"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="queryInfo.page"
          :page-size="queryInfo.limit"
          @current-change="handleCurrentChange"
          next-text="下一页"
          prev-text="上一页"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 日期选择
      dateVal: "",
      // 表格列表
      tableList: [],
      // 总数据条数
      total: 0,
      // 表格筛选数据
      queryInfo: {
        start_time: "",
        end_time: "",
        organize_id: "",
        page: 1,
        limit: 5,
      },
      // echarts 图表
      echartBox: {},
    };
  },
  mounted() {
    // 获取左侧二级菜单的数据
    this.getTreeTitleList();
    // 获取表格列表数据
    this.getTableList();
    // 获取echarts数据信息
    this.getEchart();
    // 解决echarts图表放大溢出父容器
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get("Organize");
      console.log(res);
      if (res.code !== 1) return this.$message.error("获取组织列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data;
    },
    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post("abnormal/device", this.queryInfo);
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.tableList = res.data.data;
      this.total = res.data.total;
    },

    // 分页切换 获取列表数据
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getTableList();
    },

    //  左侧二级菜单 点击事件
    handleNodeClick(data) {
      // console.log(data.id, "二级菜单");
      if (data.id) {
        this.queryInfo.organize_id = data.id;
        // 获取 点击筛选 表格/图表
        this.searchTime();
      }
    },

    // 日期选择change事件
    datePickerChange(e) {
      this.queryInfo.start_time = e[0];
      this.queryInfo.end_time = e[1];
    },

    // 搜索 点击事件
    searchTime() {
      // 获取 搜索后的 表格数据
      this.getTableList();
      // 获取 搜索后的 图表数据
      this.getEchart();
    },

    // 获取 echarts数据
    async getEchart() {
      const res = await this.$http.post("abnormal/histogram", this.queryInfo);
      if (res.code !== 1) return this.$message.error("获取图表数据失败！");
      // this.$message.success('获取设备列表成功！')
      this.echartBox = res.data;
      // 接口获取数据后，再为echarts赋值
      setTimeout(() => {
        this.initChart();
      }, 100);
    },

    // echarts 图表
    initChart() {
      var chartDom = document.getElementById("chart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.2)",
          borderWidth: "0",
          textStyle: {
            color: "#ffffff", //设置文字颜色
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["已处理", "未处理"],
          textStyle: {
            color: "#ffffff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.echartBox.name,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "次",
            min: 5,
            minInterval: 1,
            axisLabel: {
              formatter: "{value} 次",
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            name: "已处理",
            type: "bar",
            barWidth: 30,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 次";
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3271AD" },
                { offset: 1, color: "rgba(50,113,173,0)" },
              ]),
            },
            data: this.echartBox.handleYes,
          },
          {
            name: "未处理",
            type: "bar",
            barWidth: 30,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 次";
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3ED090" },
                { offset: 1, color: "rgba(62,208,144,0)" },
              ]),
            },
            data: this.echartBox.handleNo,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */
.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}
// charts 图表
.chart_box {
  width: 100%;
  height: 400px;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  // 日期搜索
  .date_search {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 50px;
    }
  }
}
.table_boxs::v-deep {
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #68d8fe;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #213058;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  // 分页
  .el-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
